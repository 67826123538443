import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function GuestAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const { register, handleSubmit, errors, getValues, setValue, reset, watch } =
    useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [passClass, setPassClass] = useState("glyphicon glyphicon-eye-open");
  const [passType, setPassType] = useState("password");
  const [passClass2, setPassClass2] = useState("glyphicon glyphicon-eye-open");
  const [passType2, setPassType2] = useState("password");

  const pincodeChange = (e) => {
    if (watch("Pincode").length == 6) {
      setIsLoading(true);
      Service.getPostalData(watch("Pincode")).then((res) => {
        setIsLoading(false);
        if (res.data[0].PostOffice) {
          setValue("State", res.data[0].PostOffice[0].State, {
            shouldValidate: true,
          });
          setValue("City", res.data[0].PostOffice[0].Block, {
            shouldValidate: true,
          });
        } else {
          setValue("Pincode", null, { shouldValidate: true });
          setValue("State", null);
          setValue("City", null);
        }
      });
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    Service.createGuestAccount(data).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        reset(null);
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        localStorage.setItem("UserLogin", res.data.UserId);
        saveToCart();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  const saveToCart = (cId, sId) => {
    var data = JSON.parse(localStorage.getItem("CheckoutData"));
    data.UserId = localStorage.getItem("UserLogin");
    Service.addToCart(data).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        localStorage.setItem("CartCountStatus", Date.now());
        history.push("/cart");
        window.location.reload();
      } else if (res.data.warning) {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="col-md-12 justify-content-center align-items-center">
      {isLoading ? <Loader /> : null}
      <div className="page-header text-center">
        <h1>Guest Checkout</h1>
        <p>
          Want to create <a href="/create">New Account ?</a>{" "}
        </p>
      </div>
      {/* End .page-header */}

      {showMsg == true ? (
        <div className={msgClass} role="alert">
          <strong>{msgText}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}

      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="signup-form"
      >
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>First Name*</label>
              <input
                type="text"
                className="form-control"
                ref={register({
                  required: "Enter first name",
                })}
                name="FName"
              />
              {errors.FName && (
                <span className="text-danger">{errors.FName.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>
          {/* End .col-sm-4 */}

          <div className="col-sm-6">
            <div className="form-group">
              <label>Last Name*</label>
              <input
                type="text"
                className="form-control"
                ref={register({
                  required: "Enter last name",
                })}
                name="LName"
              />
              {errors.LName && (
                <span className="text-danger">{errors.LName.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label>Email*</label>
              <input
                type="email"
                className="form-control"
                ref={register({
                  required: "Enter your email.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter valid email address.",
                  },
                })}
                name="Email"
              />
              {errors.Email && (
                <span className="text-danger">{errors.Email.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>
          {/* End .col-sm-4 */}

          <div className="col-sm-6">
            <div className="form-group">
              <label>Phone*</label>
              <input
                type="text"
                className="form-control"
                ref={register({
                  required: "Enter your contact.",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Enter only numbers.",
                  },
                  minLength: {
                    value: 10,
                    message: "Enter valid contact.",
                  },
                })}
                name="Contact"
              />
              {errors.Contact && (
                <span className="text-danger">{errors.Contact.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>
        </div>
        {/* End .row */}

        <div className="clearfix form-action">
          <input
            type="submit"
            className="btn btn-primary min-width pull-right"
            value="Save & Next"
          />
        </div>
        {/* End .form-action */}
      </form>
    </div>
  );
}

export default GuestAccount;
