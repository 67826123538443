import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
class ExportToExcel {
  exportToExcel = (excelData, fileName) => {
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData));
    const workbook = (XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    });
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  };
}
export default new ExportToExcel();
