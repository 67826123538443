/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Print } from "./Print";

function OrderDetails() {
  let { id } = useParams();
  useEffect(() => {
    loadOrdersDetails();
  }, []);
  const componentRef = useRef();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);

  const loadOrdersDetails = () => {
    setIsLoading(true);
    Service.getOrderDetials({ OrderId: id })
      .then((res) => {
        setOrderDetails(res.data);
        console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const exportToExcel = () => {};

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header">
        <h1>Order Details</h1>
        <hr />
      </div>

      {orderDetails.length > 0 && (
        <div className="row">
          <div className="col-sm-4">
            <h4>
              Order ID:- <b>{orderDetails[0].OrderId} </b>
            </h4>
            <h4>
              Customer Name:-{" "}
              <b>{orderDetails[0].FName + " " + orderDetails[0].LName}</b>
            </h4>
            <h4>
              Contact No:- <b>{orderDetails[0].Contact}</b>
            </h4>
          </div>
          <div className="col-sm-4">
            <h4>
              Email:- <b>{orderDetails[0].Email}</b>
            </h4>
            <h4>
              Payment Status:- <b>{orderDetails[0].PaymentStatus}</b>
            </h4>
            <h4>
              Order Status:- <b>{orderDetails[0].OrderStatus}</b>
            </h4>
          </div>
          <div className="col-sm-4">
            <h4>
              Payment Mode:- <b>{orderDetails[0].PaymentMode}</b>
            </h4>
            <h4>
              Other Charges:- <b>{orderDetails[0].OtherCharges}</b>
            </h4>

            <h4>
              Total Amount:- <b>{orderDetails[0].NetTotal} </b>
            </h4>
          </div>
          <div className="col-sm-12">
            <h4>
              Shipping / Billing Address:-{" "}
              <b>
                {orderDetails[0].Address1 +
                  ", " +
                  orderDetails[0].Address2 +
                  ", " +
                  orderDetails[0].City +
                  ", " +
                  orderDetails[0].State +
                  ", " +
                  orderDetails[0].Pincode}
              </b>
            </h4>
          </div>
        </div>
      )}
      <div className="row mt-5">
        <div className="col-sm-9 my-auto">
          <h2>Order includes following items</h2>
          <hr />
        </div>
        <div className="col-sm-3">
          <div className="clearfix form-action ">
            <ReactToPrint
              trigger={() => (
                <input
                  type="submit"
                  className="btn btn-primary min-width"
                  value="Print Invoice"
                />
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <Print ref={componentRef} data={orderDetails} hidden />
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div className="table-responsive action-btn-show-option">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Sr.No</th>
              <th>Product Name</th>
              <th className="text-center">Quantity</th>
              <th className="text-center">Price</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {orderDetails.map((item, i) => (
              <tr key={i}>
                <td className="price-col">{i + 1}</td>
                <td className="text-left product-col">
                  <div className="product">
                    <figure className="product-image-container">
                      <img
                        src={item.pImage}
                        alt={item.PName}
                        style={{ height: "100px", width: "100px" }}
                      />
                    </figure>
                    <h3 className="product-title">
                      <a>{item.PName}</a>
                    </h3>
                  </div>
                  {/* End .product */}
                </td>
                <td className="price-col">{item.Qty}</td>
                <td className="price-col">₹ {item.Price}</td>
                <td className="price-col">{item.Total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrderDetails;
