/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ExportToExcel from "../service/ExportToExcel";

function PlacedOrders() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadOrders({
      fromDate: new Date(),
      toDate: new Date(),
    });
  }, []);

  const { register, handleSubmit, errors, getValues, reset, watch } = useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [ordersData, setOrdersData] = useState([]);

  const loadOrders = (data) => {
    setIsLoading(true);
    console.log(data);
    Service.getOrdersDatewise(data).then((res) => {
      setOrdersData(res.data);
      setIsLoading(false);
    });
  };

  const getDateValues = () => {
    var data = {
      fromDate: getValues("fromDate"),
      toDate: getValues("toDate"),
    };
    loadOrders(data);
  };

  const deleteRecord = (item) => {
    setIsLoading(true);
    Service.deleteOrder(item.OrderId).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        getDateValues();
      }
      setIsLoading(false);
    });
  };

  const updateStatus = (status, orderId) => {
    setIsLoading(true);
    Service.updateOrderStatus(status, orderId).then((res) => {
      if (res.data.success) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible");
        setMsgText(res.data.success);
        getDateValues();
      }
      setIsLoading(false);
    });
  };

  const orderDetails = (item) => {
    history.push("/admin/dash/orders-details/" + item.OrderId);
  };

  const onSubmit = (data) => {
    loadOrders(data);
  };

  const exportToExcel = () => {
    if (getValues("fromDate") && getValues("toDate")) {
      setIsLoading(true);
      var data = {
        fromDate: getValues("fromDate"),
        toDate: getValues("toDate"),
      };
      Service.exportToExcel(data)
        .then((res) => {
          ExportToExcel.exportToExcel(
            res.data,
            "Sales Report " + data.fromDate + " - " + data.toDate
          );
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible");
      setMsgText("Please select dates");
    }
  };

  return (
    <div className="col-md-9 col-md-push-3">
      {isLoading ? <Loader /> : null}
      <div className="page-header">
        <h1>Placed Orders</h1>
        <hr />
      </div>

      {/* End .page-header */}

      {showMsg == true ? (
        <div className={msgClass} role="alert">
          <strong>{msgText}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}

      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="signup-form"
      >
        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <label>From Date</label>
              <input
                type="date"
                className="form-control"
                ref={register({
                  required: "Select date from",
                })}
                name="fromDate"
              />
              {errors.fromDate && (
                <span className="text-danger">{errors.fromDate.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>To Date</label>
              <input
                type="date"
                className="form-control"
                ref={register({
                  required: "Select to date.",
                })}
                name="toDate"
              />
              {errors.toDate && (
                <span className="text-danger">{errors.toDate.message}</span>
              )}
            </div>
            {/* End .form-group */}
          </div>
          <div className="col-sm-3 my-auto">
            <div className="clearfix form-action ">
              <input
                type="submit"
                className="btn btn-primary min-width"
                value="View Report"
              />
            </div>
          </div>
          <div className="col-sm-3 my-auto">
            <div className="clearfix form-action ">
              <input
                type="button"
                className="btn btn-accent min-width"
                value="Export to excel"
                onClick={exportToExcel}
              />
            </div>
          </div>
        </div>
        {/* End .row */}

        {/* End .form-action */}
      </form>

      <div className="table-responsive action-btn-show-option">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Action</th>
              {/* <th className="text-center">Sr.No</th> */}
              <th className="text-center">OrderID</th>
              <th className="text-center">Total</th>
              <th className="text-center">Customer</th>
              <th className="text-center">Contact</th>
              <th className="text-center">Payment</th>
              <th className="text-center">Status</th>
              <th className="text-center">Orders</th>
              <th className="text-center">Date</th>
              <th className="text-center">Details</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {ordersData.map((item, index) => (
              <tr key={index}>
                <td className="delete-col">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                    >
                      Action <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dw-fs">
                      <li>
                        <a
                          onClick={() =>
                            updateStatus("Order Packed", item.OrderId)
                          }
                        >
                          Order Packed
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            updateStatus("Order Shipped", item.OrderId)
                          }
                        >
                          Order Shipped
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            updateStatus("Order Delivered", item.OrderId)
                          }
                        >
                          Order Delivered
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            updateStatus("Cancelled", item.OrderId)
                          }
                        >
                          Cancel Order
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            const confirmBox = window.confirm(
                              "Do you want to delete this order..?"
                            );
                            if (confirmBox === true) {
                              deleteRecord(item);
                            }
                          }}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td className="price-col">{item.OrderId}</td>
                <td className="price-col">₹ {item.NetTotal}</td>
                <td className="price-col">
                  {item.FName} {item.LName}
                </td>
                <td className="price-col">{item.Contact}</td>
                <td className="price-col">{item.PaymentMode}</td>
                <td className="price-col">{item.PaymentStatus}</td>
                <td className="price-col">{item.OrderStatus}</td>
                <td className="price-col">
                  {new Date(item.orderDate).toLocaleDateString()}
                </td>
                <td className="price-col">
                  <img
                    onClick={() => orderDetails(item)}
                    src="assets/images/info.png"
                    alt=""
                    className="w-25"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PlacedOrders;
