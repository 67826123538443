import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  // const [bestSeller, setBestSeller] = useState([]);
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    loadSlider();
    loadTrendingProducts();
    loadAboutData();
  }, []);

  const loadAboutData = () => {
    setIsLoading(true);
    Service.getAboutData().then((res) => {
      setAboutData(res.data);
      setIsLoading(false);
    });
  };

  const loadSlider = () => {
    setIsLoading(true);
    Service.getSliders().then((res) => {
      setSliderData(res.data);
      setIsLoading(false);
    });
  };

  const loadTrendingProducts = () => {
    setIsLoading(true);
    Service.getTrendingProducts().then((res) => {
      setTrendingProducts(res.data);
      setIsLoading(false);
    });
  };

  const details = (item) => {
    history.push("/product-details/" + item.ProdId);
  };

  return (
    <>
      {/* <Slider/> */}
      <div className="row col-sm-12">
        {isLoading ? <Loader /> : null}

        {sliderData.length > 0 && (
          <div id="myCarousel" className="carousel slide mb30">
            <ol className="carousel-indicators">
              {sliderData.map((item, index) => (
                <li
                  data-target="#myCarousel"
                  data-slide-to={index}
                  className={index == 0 ? "active" : ""}
                  key={index}
                ></li>
              ))}
            </ol>

            <div className="carousel-inner">
              {sliderData.map((item, i) => (
                <div className={i == 0 ? "item active" : "item"} key={i}>
                  <img src={item.Image} />
                  <div className="carousel-caption">
                    <h3 className="text-white">{item.Heading}</h3>
                    <div className="clearfix form-action">
                      <Link
                        href={item.Url}
                        type="submit"
                        className="btn btn-primary min-width"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <a
              className="left carousel-control"
              href="#myCarousel"
              data-slide="prev"
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                style={{ marginTop: "-16px", marginLeft: "-16px" }}
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control"
              href="#myCarousel"
              data-slide="next"
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                style={{ marginTop: "-16px", marginRight: "-16px" }}
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        )}

        {trendingProducts.length > 0 && (
          <div>
            <h2 className="carousel-title text-center">Trending Products</h2>
            <div className="shop-row">
              <div className="shop-container max-col-4 col-12" data-layout="fitRows">
                {trendingProducts.map((item, index) => (
                  <div className="product-item" key={index}>
                    <div className="product text-center">
                      <figure className="product-image-container">
                        <a
                          onClick={() => details(item)}
                          title={item.PName}
                          className="product-image-link"
                        >
                          <img src={item.PImage} alt={item.PName} />
                        </a>
                        <div className="product-action">
                          <a
                            onClick={() => details(item)}
                            className="btn-product btn-add-cart"
                            title="View Details"
                          >
                            <i className="icon-product icon-bag"></i>
                            <span>View Details</span>
                          </a>
                        </div>
                      </figure>
                      <h3 className="product-title">
                        <a
                          onClick={() => details(item)}
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={item.PName}
                        >
                          {item.PName.length > 20
                            ? item.PName.substring(0, 20 - 4) + " ..."
                            : item.PName}
                        </a>
                      </h3>
                      <div className="product-price-container">
                        <span className="product-price">
                          ₹{item.DiscountedPrice}
                        </span>
                      </div>
                    </div>
                    {/* <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="card-wrapper container d-flex justify-content-center gap-5">
                            <div class="card">
                              <img src={item.PImage} alt={item.PName} />
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="card-wrapper container-sm d-flex   justify-content-around">
                            <div class="card  " >
                              <img src={item.PImage} alt={item.PName} />
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="card-wrapper container-sm d-flex  justify-content-around">
                            <div class="card " >
                              <img src={item.PImage} alt={item.PName} />
                            </div>
                          </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div> */}
                  </div>

                ))}
              </div>
            </div>
            {/* <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="card-wrapper container d-flex justify-content-center gap-5">
                  <div class="card">
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card">
                    <img src="" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card">
                    <img src="" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card">
                    <img src="" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card">
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="card-wrapper container-sm d-flex   justify-content-around">
                  <div class="card  " >
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div><div class="card">
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card" >
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="card-wrapper container-sm d-flex  justify-content-around">
                  <div class="card " >
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card">
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                  <div class="card" >
                    <img src="https://source.unsplash.com/collection/190727/1600x900" class="card-img-top" alt="..." />
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>

                    </div>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div> */}
          </div>
        )}


        {/* margin */}

        {/* <div className="page-header text-center">
        <h1>About Us</h1>
      </div>
      {aboutData.map((item, index) => (
        <p className="lead" key={index}>
          {item.Description}
        </p>
      ))} */}

        <div className="mb50 visible-sm visible-xs">

        </div>
        {/* margin */}
      </div>
    </>
  );
}

export default Home;
